<template>
  <div class="multi-category--wrapper">
    <div class="multi-category__label">
      {{ label }}
    </div>
    <div class="multi-category">
      <div
        :ref="(ref) => multiCategoryRef = ref"
        class="multi-category__list"
        :class="{
          closed: !isOpen
        }"
      >
        <button
          class="multi-category__item"
          :class="{
            active: !modelValue?.id
          }"
          @click="onClickCategory(undefined)"
        >
          Все категории
        </button>
        <button
          v-for="(category) in categories"
          :key="category.id"
          class="multi-category__item"
          :class="{
            active: category.id === modelValue?.id
          }"
          @click="onClickCategory(category)"
        >
          {{ category.title }}
        </button>
      </div>
      <div class="multi-category-control">
        <button
          v-if="isShowArrow"
          class="multi-category__item control"
          @click="isOpen = !isOpen"
        >
          <SvgIcon :src="activeIconSrc" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ICategory } from 'shared/models/category.model';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

enum EIcon {
  ArrowTop = 'navigation/arrow-top-20px',
  ArrowDown = 'navigation/arrow-down-20px',
}

const props = defineProps<{
  modelValue: ICategory | null;
  categories: Array<ICategory>;
  label?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', selectedValue: ICategory | null): void
}>();

const multiCategoryRef = ref<HTMLDivElement>();
const watchSubscription = new WatchSubscription();
const isShowArrow = ref(true);

const isOpen = ref(false);

const activeIconSrc = computed(() => isOpen.value ? EIcon.ArrowTop : EIcon.ArrowDown);

function onClickCategory(category: ICategory | null): void {
  emit('update:modelValue', category);
}

function getSumOfChildWidth(element: HTMLDivElement | undefined): number {
  return Array.from(element?.children ?? []).reduce((acc, el) => acc + el.clientWidth, 0) ?? 0;
}

function recalculateFunction() {
  const sumOfChildWidth = getSumOfChildWidth(multiCategoryRef.value);
  isShowArrow.value = (multiCategoryRef.value?.clientWidth ?? 0) < sumOfChildWidth;
}

function recalculate() {
  nextTick(() => recalculateFunction());
}

watchSubscription.add(
  watch(
    () => props.categories?.length,
    () => recalculate(),
    {
      flush: 'post',
    }),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  recalculate();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.multi-category {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-bottom: -20px;
  position: relative;
  z-index: 2;

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $multi-category-label-c;
    white-space: nowrap;
    margin-bottom: 16px;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    gap: 4px;
    flex: 1;

    &.closed {
      height: 37px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $multi-category-item-bg;
    color: $multi-category-item-c;
    border: none;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    white-space: nowrap;

    &.control {
      padding: 0;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &.active {
      color: $multi-category-item-active-c;
      background-color: $multi-category-item-active-bg;
    }
  }

  &-control {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1279px) {
  .multi-category {
    overflow-x: auto;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: -28px;

    &__list {
      flex-wrap: nowrap;
      overflow: visible;

      &.closed {
        height: 28px;
      }
    }

    &__item {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 6px;
    }

    &-control {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .multi-category {
    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
